import request from '@/utils/request'
//实习成绩接口

//获取实习计划列表
export function getpracticeattendancelist (params) {
  return request({
    url: '/portal/practice-attendance/get-list',
    method: 'GET',
    params
  })
}

//获取获取实习计划详情
export function getpracticeinfo (params) {
  return request({
    url: '/portal/practice-plan-student/get-plan-info',
    method: 'GET',
    params
  })
}

//获取实习计划设置详情(2.0)
export function getsetplaninfo (params) {
  return request({
    url: '/portal/practice-plan-setting/get-plan-info',
    method: 'GET',
    params
  })
}

//获取实习考勤汇总数据 只传plan_id
export function getpracticeattendanceinfo (params) {
  return request({
    url: '/portal/practice-attendance/get-info',
    method: 'GET',
    params
  })
}

//获取实习日志列表
export function getpracticeloglist (params) {
  return request({
    url: '/portal/practice-student-report/get-list',
    method: 'GET',
    params
  })
}

//获取实习日志详情 practice_report_id
export function getpracticeloginfo (params) {
  return request({
    url: '/portal/practice-student-report/get-info',
    method: 'GET',
    params
  })
}